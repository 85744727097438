import { useState, useEffect } from 'react'

import type { GraphQLTaggedNode, RelayProp } from 'react-relay'
import { useLazyLoadQuery } from 'react-relay'
import type { OperationType } from 'relay-runtime'
import type { FragmentRefs } from 'relay-runtime'

import type { StaticPageProps } from 'utils/page/StaticPageProps'
import { useIsAuthenticated } from 'utils/page/useIsAuthenticated'

export type ViewerType = {
    readonly ' $fragmentRefs': FragmentRefs<any>
}

export interface RelayOperation extends OperationType {
    readonly variables: {}
    readonly response: {
        viewer: ViewerType
    }
}

type PageProps<OperationType extends RelayOperation> = StaticPageProps<
    OperationType['response']['viewer']
> & {
    relayQuery: GraphQLTaggedNode
    PageComponent: React.FC<PageComponentProps<ViewerType>>
    variables: OperationType['variables']
}

export type PageComponentProps<ViewerType> = {
    viewer: ViewerType
    isAuthenticated: boolean
    relay: RelayProp
}

const StaticPageWrapper = <OperationType extends RelayOperation>({
    relay,
    relayQuery,
    variables,
    viewer: serverViewer,
    PageComponent,
}: PageProps<OperationType>) => {
    const [isClient, setIsClient] = useState(false)

    const clientData = useLazyLoadQuery<OperationType>(relayQuery, variables, {
        // We want to make sure we fetch fresh data (network-only) on the client side to make sure we're
        // pulling in user data. We want to rely on the cache initially (store-only)
        // otherwise we'll get hydration errors. This leads to unnecessary network requests when we
        // navigate to the page and already have user data, but this does not affect performance for users.
        fetchPolicy: isClient ? 'network-only' : 'store-only',
    })
    const clientAuth = useIsAuthenticated(clientData.viewer)

    // We need to make sure that we're only passing the client data to the page component
    // once we're on the client side, otherwise we'll get hydration errors.
    const viewer = isClient ? clientData.viewer : serverViewer
    const isAuthenticated = isClient ? clientAuth : false

    useEffect(() => {
        // This is set when the page has been hydrated.
        setIsClient(true)
    }, [])

    return <PageComponent viewer={viewer} isAuthenticated={isAuthenticated} {...{ relay }} />
}

export default StaticPageWrapper
